<template>
  <div>
    <div>
      <NavBar></NavBar>
    </div>
    <div>
      <div class="container">
        <h2 class="title text-center">Se ha verificado en stripe con exito!</h2>
        <v-btn x-large color="success" dark @click="Continue()">
          Continuar
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import axios from "axios";
import Parse from "parse";

export default {
  name: "StripeValidated",
  components: {
    NavBar
  },
  mounted() {
    let code = this.$route.query.code;
    // https://us-central1-coopharma-c02de.cloudfunctions.net/oauth
    // http://localhost:5001/coopharma-c02de/us-central1/oauth
    axios
      .post("https://us-central1-coopharma-c02de.cloudfunctions.net/oauth", {
        code
      })
      .then(result => {
        this.ValidateToken(result.data.stripe_user_id);
      })
      .catch(err => {
        this.$swal({
          title: "Ah ocurrido un error",
          icon: "error",
          html: err
        });
      });
  },
  methods: {
    Continue() {
      this.$router.push("/profile");
    },
    SaveToken(accountToken) {
      Parse.User.current()
        .set("stripeAccountId", accountToken)
        .save()
        .catch(err => {
          this.$swal({
            title: "Ah ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    ValidateToken(accountToken) {
      this.SaveToken(accountToken);
    }
  }
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  padding: 0 25%;
}
</style>
